<template>
  <div :class="{'dark': isDark}" class="bg-gray-100 dark:bg-gray-900 min-h-screen">
    <div id="app" class="main-container grid grid-cols-12 " >
    
      <aside v-if="user && sidebar" class="h-screen fixed md:sticky top-0 col-span-12 md:col-span-2 bg-white dark:bg-gray-800" style="z-index:100000 !important">
            <SidebarMenu v-if="user && sidebar" :isDark="isDark"class="md:w-full" />
      </aside>
      <!-- Dark Mode Toggle Button -->
   
      <div class="w-full col-span-12" :class="`${sidebar ? 'md:col-span-10' : ''}`">
          <HeaderMenu v-if="user" :isDark="isDark" :toggleDarkMode="toggleDarkMode"/>
          <transition name="fade">
              <router-view/>
          </transition>
      </div>
      
            
    </div>
    <dangerAlert :data="data_danger" v-on:stop="stop()" v-if="dialog_danger" style="z-index:111000 !important"/>
        <audio style="display: none;" id="dangerAlert">
            <source :src="require('./assets/emergency.mp3')" type="audio/mpeg">
        </audio>
      <changePassword style="z-index:110000 !important" v-if="dialogChangePassword"/>
      
  </div>
  
</template>
<style>
.dark .main-container {
  background-color: #1a222c; /* Dark color for main container */
}
.dark {
  color: white; /* Gaya teks putih untuk semua elemen saat dark mode */
  
}
.dark .bg-white{
  background-color: #1f2937;
  
}
</style>
<script>
import {mapGetters} from 'vuex'
import SidebarMenu from '@/components/layouts/SidebarMenu.vue'
import HeaderMenu from '@/components/layouts/HeaderMenu.vue'
import { useFavicon } from '@vueuse/core'
import dangerAlert from '@/components/dangerAlert.vue'
import changePassword from '@/components/changePassword.vue'
const icon = useFavicon()
export default {
  data() {
    return {
      test:'test',
      default_url:process.env.VUE_APP_IMAGES,
      first_danger:true,
      mute: true,
      audio2: null,
      data_danger:null,
      dialog_danger:false,
      isDark: false,


    }
  },
   sockets: {
    connect: function () {
        console.log('socket connected')
    },
    disconnect() {
      console.log("Socket Galih 断开");
  },
    customEmit: function (data) {
        console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  mounted(){
    this.sockets.subscribe('dangeralert', (data) => {
      console.log(data)
      console.log(this.$route.path)
      if(this.user && this.$route.path !=='/monitor/detail')
      if(data) {
        this.data_danger = data
        this.dialog_danger = true
        this.play()
      }
    });
    this.audio2 = document.getElementById('dangerAlert');
    this.loadTheme(); // Load the theme on component mount
  },
  components:{
    SidebarMenu,HeaderMenu,dangerAlert,changePassword
  },
  computed:{
    ...mapGetters({
      user:'auth/user',
      detail:'auth/detail',
      sidebar:'sidebar/sidebar',
      dialogChangePassword:'data/dialogChangePassword'
    })
  },
  methods:{
    
      play(){
          
          this.audio2.play();
          this.audio2.loop = true;
          this.mute = false;
          
      },
      stop() {
          this.audio2.pause();
          this.mute = true;
          this.dialog_danger = false
        
      },
      toggleDarkMode() {
      this.isDark = !this.isDark;
      // Store the theme preference in localStorage
      localStorage.setItem('theme', this.isDark ? 'dark' : 'light');
    },
    loadTheme() {
      const storedTheme = localStorage.getItem('theme');
      this.isDark = storedTheme === 'dark';
    }
  },

  watch:{
    user: function (newQuestion, oldQuestion) {
        // if (this.detail) {
        // icon.value = this.default_url + this.detail.foto
        //   } else {
            icon.value = this.default_url + 'favicon.ico'
        // }
    
    }
  },
  created() {
    
  },
  
}
</script>
