export default {
    path:'/commandcenter',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Command Center',
            component : () => import('@/views/command_center/index.vue')
        },
        // {
        //     path:'add',
        //     name:'tiket.tambah',
        //     component : () => import('@/views/command_center/create.vue')
        // },
        // {
        //     path:'edit/:id',
        //     name:'tiket.ubah',
        //     component : () => import('@/views/command_center/edit.vue')
        // },
        // {
        //     path:'detail/:id',
        //     name:'tiket.detail',
        //     component : () => import('@/views/command_center/detail.vue')
        // },
        
    ]
}

