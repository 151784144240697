import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/app.css';
import VueTailwind from 'vue-tailwind/dist/full'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import VeeValidate from 'vee-validate';
import 'leaflet/dist/leaflet.css';
import VueSnackbar from 'vue-snack' 
import 'vue-snack/dist/vue-snack.min.css'
import VueCompositionAPI from '@vue/composition-api'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {uniExclamationCircle,uniBell,uniExclamationTriangle,uniTear,uniDownloadAlt,uniSignal ,uniSimCard,uniCalendarAlt,uniBrightness, uniLocationPoint,uniRaindrops,uniTemperatureHalf, uniCloudShowersHeavy ,uniWind , uniLayerGroup,uniWater,uniAngleRight,uniSearch,uniApps,uniAngleDown,uniAlignLeft,uniAngleLeft,uniMonitor, uniSitemap, uniUser, uniFileContract, uniDesktop, uniCircuit, uniCog, uniSetting, uniHistory,uniUsersAlt,uniClock,uniKeySkeleton, uniTrashAlt, uniEdit, uniRedo, uniTimes, uniCamera,uniTicket,uniLaptopCloud, uniMoon,uniSun} from 'vue-unicons/dist/icons'
import VueSocketIO from 'vue-socket.io'
import VueQRCodeComponent from 'vue-qrcode-component'


// Vue.directive('click-outside', ClickOutside)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Unicon.add([uniExclamationCircle,uniBell,uniExclamationTriangle,uniTear,uniDownloadAlt,uniSignal ,uniSimCard,uniCalendarAlt, uniBrightness,uniLocationPoint,uniRaindrops,uniTemperatureHalf,uniLayerGroup, uniCloudShowersHeavy , uniWind ,uniAngleRight,uniWater,uniSearch,uniApps,uniAlignLeft,uniAngleLeft,uniAngleDown,uniMonitor, uniSitemap, uniUser, uniFileContract, uniDesktop, uniCircuit, uniCog, uniSetting, uniHistory,uniUsersAlt,uniClock,uniKeySkeleton, uniTrashAlt, uniEdit, uniRedo, uniTimes, uniCamera, uniTicket,uniLaptopCloud,uniMoon,uniSun ])
Vue.use(Unicon)

Vue.config.productionTip = false
Vue.use(VueTailwind)
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('v-select', vSelect)
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_BACKENDHOST,
  // vuex: {
  //     store,
  //     actionPrefix: 'SOCKET_',
  //     mutationPrefix: 'SOCKET_'
  // },
  // options: { path: "/my-app/" } //Optional options
}))
// Vue.use(VeeValidate);
Vue.use(VueSnackbar, { position: 'top', time: 3000 })


new Vue({
  router,
  store,
  // sockets: {
  //   connect: function () {
  //       console.log('socket connected')
  //   },
  //   disconnect() {
  //     console.log("Socket 断开");
  // },
  //   customEmit: function (data) {
  //       console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
  //   }
  // },
  // mounted(){
  //   this.sockets.subscribe('welcome', (data) => {
  //     console.log(data)
  //     console.log(this.$route.path)
  //   });
  // },
  methods: {
   
  },
  render: h => h(App)
}).$mount('#app')
